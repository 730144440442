import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Document, Page, pdfjs } from 'react-pdf';

import { PageContainer } from '../styles/pages.css';
import { GlobalStyle } from '../styles/common.css';
import mypdf from "../files/af-tou.pdf";
const isBrowser = typeof window !== "undefined"

export default ({ data }) => {
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(440);

  useEffect(() => {
    if (isBrowser) {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      setWindowWidth(window.innerWidth);
    }

    const handleResize = () => {
      if (isBrowser) {
        setWindowWidth(window.innerWidth);
      }
    };

    if (isBrowser) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const calculaePdfWidth = () => {
    if (windowWidth > 950) {
      return 850;
    }

    return windowWidth * 0.85;
  }

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <Img className="cover" fluid={data.datoCmsPrivacy.cover.fluid} />
        <div className="pdf-content">
          <Link to="/"> ⬅ Back</Link>
          <div className="pdf-wrapper" style={{ width: calculaePdfWidth() }}>
            {isBrowser === true &&
              <Document file={mypdf} onLoadSuccess={onDocumentLoadSuccess} >
                {[...Array(numPages)].map((_, index) => (

                  <Page pageNumber={index + 1}
                    key={(index)}
                    renderAnnotationLayer={false}
                    renderMode="svg"
                    className="page"
                    width={calculaePdfWidth()}
                  />
                ))}
              </Document>
            }
          </div>
        </div>

      </PageContainer>
    </>
  );
};

export const query = graphql`
  query {
    datoCmsPrivacy {
      cover {
        url
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      title
      subtitle
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
